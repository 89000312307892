<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button slot="append" type="primary" icon="el-icon-search" @click="submitSearch"></el-button>
          </el-input>
        </div>
        <div>
          <el-button type="text" class="fs-16" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </el-button>
        </div>
      </div>
    </div>
    <div class="pv-30 pt-10 pb-30 el-tabs-scrollY">
      <el-tabs v-model="activeName" @tab-click="handleClick" tabPosition="left">
        <el-tab-pane v-for="(item,index) in tabPaneList" :key="index" :label="item.name" :name="item.value"
          class="flex-none">
          <TableHeightFull v-if="item.value == activeName">
            <!-- 表格 -->
            <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="grade_columns" height="100%"
              :border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }"
              v-if="item.value == 'find_grade'">
            </EleTable>
            <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="acty_columns" height="100%"
              :border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }"
              v-if="item.value.includes('acty_grade')">
            </EleTable>
            <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" v-else
              :border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
            </EleTable>
            <!-- 分页 -->
            <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
          </TableHeightFull>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="30vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="键" prop="index" v-if="'index' in ruleForm"
          :rules="{required: true, message: '键不能为空', trigger: 'blur'}">
          <el-input type="number" :disabled="diaDisabled" v-model="ruleForm.index"></el-input>
        </el-form-item>
        <el-form-item label="值" prop="value" v-if="'value' in ruleForm"
          :rules="{required: true, message: '值不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.value"></el-input>
        </el-form-item>
        <el-form-item label="商品等级" prop="grade" v-if="'grade' in ruleForm"
          :rules="{required: true, message: '等级不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.grade"></el-input>
        </el-form-item>
        <el-form-item label="活动等级" prop="levelName" v-if="'levelName' in ruleForm"
          :rules="{required: true, message: '等级不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.levelName"></el-input>
        </el-form-item>
        <el-form-item label="累计消费" prop="consume" v-if="'consume' in ruleForm"
          :rules="{required: true, message: '累计消费不能为空', trigger: 'blur'}">
          <el-input v-model="ruleForm.consume"></el-input>
        </el-form-item>
        <!-- <el-form-item label="活动类型" prop="actyType" v-if="'actyType' in ruleForm"
          :rules="{required: true, message: '请至少选择一个活动类型', trigger: 'change'}">
          <el-select class="w-250" v-model="ruleForm.actyType" clearable placeholder="请选择">
            <el-option v-for="item in actyTypes" :key="item.value" :label="item.key" :value="item.value"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'dictionaries',
  mixins: [indexMixin],
  components: {
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "CountDown": (resolve) => require(["@/components/CountDown"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'actyTypes': state => state.dictList ? state.dictList.acty_type : [],  //系列分类
    })
  },
  data () {
    return {
      activeName: 'box_type',
      tabPaneList: [],
      butLoading: false, //按钮
      tabLoading: false, //表单
      retrieveForm: {
        searchValue: '',
      },
      columns: [
        { label: '键', prop: 'index', align: 'left', 'show-overflow-tooltip': true },
        { label: '值', prop: 'value', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', width: '200',
          buttons: [
            { type: 'text', text: '修改', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        },
      ],
      grade_columns: [
        { label: '商品等级', prop: 'grade', align: 'left', 'show-overflow-tooltip': true },
        { label: '必中金额', prop: 'consume', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'button', label: '操作', align: 'right', width: '100',
          buttons: [
            {
              type: 'text', text: '修改', fn: this.editList, hidden: (e) => {
                return e.id != 12 && e.id != 13
              }
            },
            {
              type: 'text', text: '删除', fn: this.deleteList, hidden: (e) => {
                return e.id != 12 && e.id != 13
              }
            }
          ],
        },
      ],
      acty_columns: [
        { label: '活动等级', prop: 'levelName', align: 'left', 'show-overflow-tooltip': true },
        { label: '累计消费', prop: 'consume', align: 'left', 'show-overflow-tooltip': true },
        {
          columnType: 'fun', label: '活动分类', prop: 'actyType', align: 'left', 'show-overflow-tooltip': true, formatData: (e) => {
            let ele = this.actyTypes.find(ele => ele.value == e)
            return ele ? ele.key : e
          }
        },
        {
          columnType: 'button', label: '操作', align: 'right', width: '100',
          buttons: [
            { type: 'text', text: '修改', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList }
          ],
        }
      ],
      tableData: [],
      //弹框
      dialogFormVisible: false,
      dialogTitle: '新增',
      diaDisabled: false,
      ruleForm: {
        //分类
        index: '',
        type: '',
        value: '',
        //一番赏价格等级
        actyType: '',
        consume: '',
        levelName: '',
        //活动价格等级
        consume: '',
        grade: '',
      },
    }
  },
  mounted () {
    this.tabPanes()
  },
  methods: {
    //搜索
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //获取所有分类
    tabPanes () {
      this.$http.get('config/findConfigType').then(({ data: result }) => {
        let list = result.data
        let tabPaneList = list.map(ele => {
          switch (ele) {
            case 'box_type':
              return {
                name: '商品分类',
                value: ele,
              }
            case 'coupons_type':
              return {
                name: '优惠劵类型',
                value: ele,
              }
            case 'goods_mall_type':
              return {
                name: '精品商城分类',
                value: ele,
              }
            case 'good_series_type':
              return {
                name: '一番赏分类',
                value: ele,
              }
            case 'level_type':
              return {
                name: '前端展示等级',
                value: ele,
              }
            case 'series_type':
              return {
                name: '抽盒分类',
                value: ele,
              }
            case 'shelves':
              return {
                name: '上下架',
                value: ele,
              }
            case 'prop_type':
              return {
                name: '道具分类',
                value: ele,
              }
            default:
              return false
          }
        }).filter(item => item)
        tabPaneList.push({
          name: '一番赏价格等级',
          value: 'find_grade',
        })
        let actyTypes = this.actyTypes
        if (actyTypes) {
          actyTypes.forEach(element => {
            tabPaneList.push({
              name: element.key + '等级',
              value: 'acty_grade' + element.value,
            })
          })
        }
        this.tabPaneList = tabPaneList
        this.activeName = tabPaneList[0].value
        this.getTableData()
      })
    },
    //切换标签页
    handleClick (data) {
      console.log(data.name)
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      if (this.activeName == 'find_grade') {
        this.$http.get('/grade/findGrade', {
          params: {
            currentPage: this.pageInfo.pageNum,
            pageSize: this.pageInfo.pageSize,
            gradeName: this.retrieveForm.searchValue,
          }
        }).then(({ data: result }) => {
          this.tabLoading = false
          this.tableData = result.data.list
          this.pageInfo.total = result.data.total
        })
      } else if (this.activeName.includes('acty_grade')) {
        this.$http.get('/actyLevelConsume/findActyLevelConsumeList', {
          params: {
            currentPage: this.pageInfo.pageNum,
            pageSize: this.pageInfo.pageSize,
            actyType: this.activeName.replace('acty_grade', ''),
          }
        }).then(({ data: result }) => {
          this.tabLoading = false
          this.tableData = result.data.list
          this.pageInfo.total = result.data.total
        })
      } else {
        this.$http.get('/config/findConfig', {
          params: {
            currentPage: this.pageInfo.pageNum,
            pageSize: this.pageInfo.pageSize,
            type: this.activeName,
            value: this.retrieveForm.searchValue,
          }
        }).then(({ data: result }) => {
          this.tabLoading = false
          this.tableData = result.data.list
          this.pageInfo.total = result.data.total
        })
      }
    },
    //删除
    deleteList (data) {
      this.$confirm('请确认您是否解除一切有关分类绑定，删除后会影响系统数据。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(_ => {
        this.$confirm('您确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(_ => {
          let apiUrl = ''
          if (this.activeName == 'find_grade') {
            apiUrl = '/grade/removeGrade'
          } else if (this.activeName.includes('acty_grade')) {
            apiUrl = '/actyLevelConsume/removeActyLevelConsumeById'
          } else {
            apiUrl = '/config/removeConfigById'
          }
          this.$http.post(apiUrl, { id: data.id }).then(({ data: result }) => {
            this.getTableData()
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            if (this.activeName != 'find_grade' && !this.activeName.includes('acty_grade')) {
              this.$store.dispatch('menu/findConfigs') //刷新字典
            }
          })
        }).catch(_ => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      }).catch(_ => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    //新增列表
    addList () {
      this.dialogTitle = '新增'
      this.diaDisabled = false
      if (this.activeName == 'find_grade') {
        this.ruleForm = {
          consume: '',
          grade: '',
        }
      } else if (this.activeName.includes('acty_grade')) {
        this.ruleForm = {
          actyType: this.activeName.replace('acty_grade', ''),
          consume: '',
          levelName: ''
        }
      } else {
        this.ruleForm = {
          type: this.activeName,
          index: this.tableData[0].index + 1,
          value: ""
        }
      }
      this.dialogFormVisible = true
    },
    //修改
    editList (row) {
      console.log(row)
      this.dialogTitle = '修改'
      this.diaDisabled = true
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ''
          if (this.dialogTitle == '新增') {
            if (this.activeName == 'find_grade') {
              apiUrl = '/grade/addGrade'
            } else if (this.activeName.includes('acty_grade')) {
              apiUrl = '/actyLevelConsume/addActyLevelConsume'
            } else {
              apiUrl = '/config/addConfig'
            }
            this.$http.post(apiUrl, this.ruleForm).then(({ data: result }) => {
              this.dialogFormVisible = false
              this.$message({
                message: '新增成功',
                type: 'success'
              })
              this.butLoading = false
              this.getTableData()
              if (this.activeName != 'find_grade' && !this.activeName.includes('acty_grade')) {
                this.$store.dispatch('menu/findConfigs') //刷新字典
              }
            }).catch(err => {
              this.butLoading = false
            })
          } else if (this.dialogTitle == '修改') {
            if (this.activeName == 'find_grade') {
              apiUrl = '/grade/editGrade'
            } else if (this.activeName.includes('acty_grade')) {
              apiUrl = '/actyLevelConsume/editActyLevelConsume'
            } else {
              apiUrl = '/config/editConfigById'
            }
            this.$http.post(apiUrl, this.ruleForm).then(({ data: result }) => {
              this.dialogFormVisible = false
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.butLoading = false
              this.getTableData()
              if (this.activeName != 'find_grade' && this.activeName.includes('acty_grade')) {
                this.$store.dispatch('menu/findConfigs') //刷新字典
              }
            }).catch(err => {
              this.butLoading = false
            })
          }
        }
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log(row)
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.$data.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
// ::v-deep .el-tabs__content {
//   height: 100%;
//   flex: 1;
// }
</style>